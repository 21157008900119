import React, { useState, useContext } from 'react';
import FadeTransition from 'components/fade-transition';
import { Link } from 'react-router-dom';
import { NavLink } from 'types';
import './index.scss';
import NavMenuButton from './nav-menu-button';
import { MatomoContext } from 'utils/matomo-context-provider';

interface MobileSideNavProps {
  routeData: Array<NavLink>;
  activeRouteData: NavLink;
  hideNav: boolean;
}

const MobileSideNav = (props: MobileSideNavProps) => {
  const { routeData, activeRouteData, hideNav } = props;
  const [menuActive, setMenuActive] = useState(false);
  const context = useContext(MatomoContext);
  const renderNavLinks = () => {
    const navLinks: JSX.Element[] = [];
    routeData.forEach(link => {
      const linkActive = activeRouteData.url === link.url;
      const linkAuthorized = link.authorized;
      if (linkAuthorized) {
        navLinks.push(
          <Link
            key={link.label}
            className="mobile-nav-link"
            onClick={() => {
              context.trackEvent(
                'btn_click_event',
                'Top Navigation',
                link.label.toUpperCase(),
              );
              context.trackPageView(
                window.location.origin + '/' + link.url,
                document.title,
              );

              setMenuActive(false);
            }}
            to={link?.url ?? '/'}
          >
            <p
              className={`mobile-nav-link-text ${
                linkActive ? 'mobile-nav-link-text--active' : ''
              }`}
            >
              {link.label}
            </p>
          </Link>,
        );
      }
    });

    return navLinks;
  };

  return (
    <div id="mobile-side-nav" className={` ${hideNav ? 'dct-nav-hide' : ''}`}>
      <NavMenuButton
        isActive={menuActive}
        onClick={() => setMenuActive(!menuActive)}
      />
      <div
        className={`mobile-side-nav-drawer ${
          menuActive ? 'mobile-side-nav-drawer--nav-active' : ''
        }`}
      >
        <FadeTransition itemKey={menuActive.toString()}>
          {menuActive && <>{renderNavLinks()}</>}
        </FadeTransition>
      </div>
    </div>
  );
};

export default MobileSideNav;
