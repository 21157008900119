import React from 'react';
import { createContext, useCallback, useMemo } from 'react';

import { AmgenMatamoTracker } from './amgen-matomo';

const MatomoContext = createContext<any>(null);

export const MatomoContextProvider = ({ children, ...props }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noMatomoHandler = (...args: any[]) => {};

  const dimensions = props.dimensions;
  const providedNoMatamoValue = useMemo(
    () => ({
      trackEvent: noMatomoHandler,
      trackPageView: noMatomoHandler,
      trackSiteSearch: noMatomoHandler,
      trackCustomDimensions: noMatomoHandler,
      trackApplicationFeature: noMatomoHandler,
      // FACET_FEATURE_ACTIONS,
      dimensions,
    }),
    [dimensions],
  );

  const siteIDnumber = props.siteId;
  const siteID = Number(siteIDnumber);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const AmgenMatomo = useMemo(() => {
    return new AmgenMatamoTracker({
      url: props.url ?? '',
      siteId: siteID ?? 0,
      pathBasename: 'DCT-Analytics',
    });
  }, [props.url, siteID]);

  AmgenMatomo.connectToHistory(props.history);

  const trackEvent = useCallback(
    (category: any, action: any, name: any, value: any) => {
      try {
        AmgenMatomo.push(['trackEvent', category, action, name, value]);
      } catch (error) {
        console.log(error);
      }
    },
    [AmgenMatomo],
  );

  const trackEachPageView = useCallback(
    (url: any, Title: any) => {
      try {
        // debugger;
        AmgenMatomo.push(['enableHeartBeatTimer', 15]);
        if (url) {
          AmgenMatomo.push(['setCustomUrl', url]);
          AmgenMatomo.push(['setDocumentTitle', Title]);
        }

        // AmgenMatomo.push(['trackPageView']);
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AmgenMatomo],
  );

  const trackCustomDimensions = useCallback(
    (value: any, dimensionId: any) => {
      if (dimensionId) {
        AmgenMatomo.push([
          'trackPageView',
          document.title,
          { [dimensionId]: value },
        ]);
      }
    },
    [AmgenMatomo],
  );

  const trackSiteSearch = useCallback(
    (searchKey: any, category: any, filters: any) => {
      try {
        AmgenMatomo.push(['trackSiteSearch', searchKey, category, filters]);
      } catch (error) {
        console.log(error);
      }
    },
    [AmgenMatomo],
  );

  const setCustomVariable = useCallback(
    (index: any, names: any, value: any, scope = 'visit') => {
      try {
        AmgenMatomo.push(['setCustomVariable', index, names, value, scope]);
      } catch (error) {
        console.log(error);
      }
    },
    [AmgenMatomo],
  );

  const trackApplicationFeature = useCallback(
    (category: any, action: string, name: any, value: string) => {
      if (action === 'download') {
        AmgenMatomo.push(['trackLink', value, 'download']);
      }
      trackEvent(category, action, name, value);
    },
    [trackEvent, AmgenMatomo],
  );
  const providedValue = useMemo(
    () => ({
      trackEvent,
      trackPageView: trackEachPageView,
      trackSiteSearch,
      trackCustomDimensions,
      trackApplicationFeature,
      setCustomVariable,
      dimensions,
    }),
    [
      dimensions,
      trackCustomDimensions,
      setCustomVariable,
      trackEachPageView,
      trackEvent,
      trackSiteSearch,
      trackApplicationFeature,
    ],
  );

  if (!props.url || !props.siteId) {
    return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <MatomoContext.Provider value={providedNoMatamoValue}>
        {children}
      </MatomoContext.Provider>
    );
  }

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <MatomoContext.Provider value={providedValue}>
      {children}
    </MatomoContext.Provider>
  );
};

export default MatomoContextProvider;
export { MatomoContext };
