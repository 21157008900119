const REACT_APP_URLS = {
  dev: 'web-dev.dct.amgen.com',
  tst: 'web-tst.dct.amgen.com',
  stg: 'web-stg.dct.amgen.com',
  prd: 'web.dct.amgen.com',
};

const MATOMO_APP_URLS = {
  dev: 'https://matomo.devops.amgen.com/',
  tst: 'https://matomo.devops.amgen.com/',
  stg: 'https://matomo.devops.amgen.com/',
  prd: 'https://matomo.devops.amgen.com/',
};

const MATOMO_SITE_IDS = {
  dev: '107',
  tst: '109',
  stg: '110',
  prd: '111',
};

export const MATOMO = {
  config: () => {
    switch (window.location.hostname) {
      case REACT_APP_URLS.dev:
        return { url: MATOMO_APP_URLS.dev, siteId: MATOMO_SITE_IDS.dev };
      case REACT_APP_URLS.tst:
        return { url: MATOMO_APP_URLS.tst, siteId: MATOMO_SITE_IDS.tst };
      case REACT_APP_URLS.stg:
        return { url: MATOMO_APP_URLS.stg, siteId: MATOMO_SITE_IDS.stg };
      case REACT_APP_URLS.prd:
        return { url: MATOMO_APP_URLS.prd, siteId: MATOMO_SITE_IDS.prd };
      default:
        return { url: MATOMO_APP_URLS.dev, siteId: MATOMO_SITE_IDS.dev };
    }
  },
};
