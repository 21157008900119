/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {Loader} from '@gitlab-rtsensing/component-library'
// FIXME: when logo available for DCT need to change
// import Logo from '../../assets/images/sensing.png';

import './index.scss';


interface TransitionWaterfallProps {
  endTransitionHandler: (miliseconds: number) => void;
}

/**
 * Animated transition which will render the component in, and then will animate the component out after a brief delay. The parent
 * is then informed when the animation has completed, and it passes number of miliseconds to wait before the parent can be unmounted
 * @param {function} endTransitionHandler - Parent function used to inform the parent that the animation has ended. The amount of
 * miliseconds that are required for the exit transition to complete must be passed to the parent to ensure the parent is not
 * unmounted before the animation completes
 */
const TransitionWaterfall = (props: TransitionWaterfallProps) => {
  const contentRoot =
    document.getElementById('dct-main-content') ?? ({} as Element);
  const [waterRunning, setWaterRunning] = useState(true); 

  const endWaterfall = async () => {
    setTimeout(() => {
      debugger
      setWaterRunning(false);
      props.endTransitionHandler(1050);
    }, 2050);
  };
  useEffect(() => {
    endWaterfall()
  },[])

  return createPortal(
    <div className="dct-nav-transition-wave-container">
      <Loader status='loading' />
    </div>,
    contentRoot,
  );
};

export default TransitionWaterfall;
