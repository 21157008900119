import React from 'react';
import { createPortal } from 'react-dom';

import './index.scss';
import FadeTransition from 'components/fade-transition';
import { NavLink } from 'types';
import { confidentialityMap } from 'mocks/links';
import { useNavigate } from 'react-router-dom';
import { SpeechBubbleIcon } from '@gitlab-rtsensing/component-library';
import ServiceRequestIcon from 'assets/icons/service-request-icon';

interface FooterProps {
  activeRouteData: NavLink;
  toggleFeedbackModal: (type: 'CONTACT' | 'FEEDBACK' | 'ISSUE') => void;
}

const Footer = (props: FooterProps) => {
  const { activeRouteData, toggleFeedbackModal } = props;
  const contentRoot =
    document.getElementsByTagName('main')[0] ?? ({} as Element);

  const navigate = useNavigate();

  const getConfidentialityClass = () => {
    return `dct-confidential-label--internal`;
  };

  return createPortal(
    <div className="dct-footer-wrapper">
      <FadeTransition itemKey={activeRouteData.label}>
        <div className="dct-foot-layout">

          <h3
            className={`dct-confidential-label ${getConfidentialityClass()} `}
          >
            {confidentialityMap?.internal_ga?.label.toUpperCase()}
          </h3>
          <p className="dct-confidential-label dct-confidential-label--sublabel ">
            {confidentialityMap?.internal_ga?.subLabel?.toUpperCase()}
          </p>


        </div>
      </FadeTransition>
      <div className="aoh-d-flex aoh-justify-content-between footer-nav">
        <p className="dct-confidential-label dct-confidential-label--serviceRequest"
          onClick={() => {
            window.open(
              'https://amgennow.service-now.com/it_self_service?id=sc_cat_item&sys_id=bee777001b919d506850eb522a4bcbfc',
              '_blank',
            );
          }}
        >
          <div className='dct-confidential-label dct-confidential-label dct-feedback-icon'>
            <ServiceRequestIcon height={12} width={12} fill="#9C9C9C" />
          </div>
          {confidentialityMap?.service_req?.label.toUpperCase()}
        </p>
        <p className="dct-confidential-label dct-confidential-label--feedback aoh-pl-2 aoh-ml-2"
          onClick={() => {
            window.open(
              'https://app.smartsheet.com/b/form/6868de1d74744d389034a26cb5ab392f',
              '_blank',
            );
          }}
        > <div className='dct-confidential-label dct-confidential-label dct-feedback-icon'>
            <SpeechBubbleIcon height={12} width={12} />
          </div>
          {confidentialityMap?.feedback?.label?.toUpperCase()}
        </p>
      </div>
      {/* <div className="dct-footer-link-group">
        <SectionButtonLink
          className="conf-link-nav-icon"
          onClick={event => toggleFeedbackModal('ISSUE')}
        >
          <InfoIcon height={12} width={12} />
          Report Inaccuracy
        </SectionButtonLink>
        <SectionButtonLink
          className="conf-link-nav-icon"
          onClick={event => toggleFeedbackModal('FEEDBACK')}
        >
         
          Feedback
        </SectionButtonLink>
        <SectionButtonLink
          className="conf-link-nav-icon"
          onClick={event => toggleFeedbackModal('CONTACT')}
        >
          <MailIcon height={12} width={12} />
          Contact Us
        </SectionButtonLink> */}
      {/* </div> */}
    </div>,
    contentRoot,
  );
};

export default Footer;
