export function toCamelCase(sentence: string) {
  return sentence
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join('');
}

export function caseInsensitiveMatch(str1: string, str2: string, navLinkArray: string[]): boolean {
  const checker =  navLinkArray.filter((item: string) => str2.split("/").includes(item))
  return checker.length > 0 && checker[0].toLowerCase() === str1.toLowerCase();
}
