import { ConfidentialityMap, NavLink } from 'types';

export const confidentialityMap: ConfidentialityMap = {
  confidential_ga: {
    label: 'Confidential General and Administrative',
    type: 'confidential',
  },
  confidential_hr: {
    label: 'Confidential Human Resources',
    type: 'confidential',
  },
  confidential_ms: {
    label: 'Confidential Marketing and Sales',
    type: 'confidential',
  },
  confidential_fa: {
    label: 'Confidential Finance and Accounting',
    type: 'confidential',
  },
  internal_ga: {
    label: 'Internal Use Only General and Administrative',
    type: 'internal',
    subLabel: 'Data for business decisions and not intended for GXP use'
  },
  feedback: {
    label: 'Feedback',
    type: 'internal',
  },
  service_req:{
    label: 'Service Request',
    type: 'internal'
  },
};


//   {
//     label: 'Home',
//     url: '/home',
//     confidentiality: confidentialityMap.confidential_ga,
//     internalSections: [
//       {
//         sectionLabel: '',
//         links: [
//           {
//             label: 'Prioritized Agenda',
//             url: '/prioritized-agenda',
//             disabled: false,
//           },
//           {
//             label: 'People',
//             url: '/people',
//             disabled: false,
//           },
//           {
//             label: 'Brand',
//             url: '/brand',
//             disabled: false,
//           },
//           {
//             label: 'Supply',
//             url: '/supply',
//             disabled: false,
//           },
//           {
//             label: 'Pipeline',
//             url: '/pipeline',
//             disabled: false,
//           },
//           {
//             label: 'Finance',
//             url: '/finance',
//             disabled: false,
//           },
//           {
//             label: 'External',
//             url: '/external',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     label: 'Prioritized Agenda',
//     url: '/prioritized-agenda',
//     confidentiality: confidentialityMap.confidential_ga,
//   },
//   {
//     label: 'People',
//     url: '/people',
//     confidentiality: confidentialityMap.confidential_hr,
//     internalSections: [
//       {
//         sectionLabel: '',
//         links: [
//           {
//             label: 'Key Products',
//             url: '#key-products',
//             disabled: false,
//           },
//           {
//             label: 'Culture',
//             url: '#culture',
//             disabled: false,
//           },
//           {
//             label: 'Talent Attraction',
//             url: '#talent-attraction',
//             disabled: false,
//           },
//           {
//             label: 'Talent Development',
//             url: '#talent-development',
//             disabled: false,
//           },
//           {
//             label: 'Talent Retention',
//             url: '#talent-retention',
//             disabled: false,
//           },
//         ],
//       },
//     ],

//     externalSections: [
//       {
//         sectionLabel: 'Related Links',
//         links: [
//           {
//             label: 'Visier',
//             url: 'https://prod-ac00.app.visier.com/hr/prod/appcontainer',
//             disabled: false,
//           },
//           {
//             label: 'Glint',
//             url: 'https://app.glintinc.com/session/auth?clientUuid=amgen',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     label: 'Brand',
//     url: '/brand',
//     confidentiality: confidentialityMap.confidential_ms,
//     internalSections: [
//       {
//         sectionLabel: '',
//         links: [
//           {
//             label: 'Brands at a Glance',
//             url: '#brand-at-glance',
//             disabled: false,
//           },
//           {
//             label: 'Repatha',
//             url: '#repatha',
//             disabled: false,
//           },
//           {
//             label: 'Otezla',
//             url: '#otezla',
//             disabled: false,
//           },
//           {
//             label: 'Enbrel',
//             url: '#enbrel',
//             disabled: false,
//           },
//           {
//             label: 'Prolia',
//             url: '#prolia',
//             disabled: false,
//           },
//           {
//             label: 'Global Brand Sales Drivers',
//             url: '#global',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     label: 'Supply',
//     url: '/supply',
//     confidentiality: confidentialityMap.confidential_ga,
//   },
//   {
//     label: 'Pipeline',
//     url: '/pipeline',
//     confidentiality: confidentialityMap.confidential_ga,
//     jumpToSections: [
//       {
//         sectionLabel: '',
//         links: [
//           {
//             label: 'Key Events',
//             url: '#key-events',
//             disabled: false,
//           },
//           {
//             label: 'Key Products',
//             url: '#key-products',
//             disabled: false,
//           },
//           {
//             label: 'Product Reference',
//             url: '#product-reference',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//     externalSections: [
//       {
//         sectionLabel: 'Related Links',
//         links: [
//           {
//             label: 'Mercury Portal',
//             url: 'https://portfolio-analytics.amgen.com/',
//             disabled: false,
//           },
//           {
//             label: 'Key Events Dashboard',
//             url: 'https://portfolio-analytics.amgen.com/dashboard/key-events-dashboard-key-events-dashboard',
//             disabled: false,
//           },
//           {
//             label: 'Program Level Dashboard',
//             url: 'https://portfolio-analytics.amgen.com/dashboard/program-level?Prioritized%20Agenda%20Toggle=CEO%20Staff%20Tracked',
//             disabled: false,
//           },
//           {
//             label: 'Enrollment Indicator Dashboard',
//             url: 'https://tspotfireweb.amgen.com/spotfire/wp/OpenAnalysis?file=/GDO%20%28Global%20Development%20Operations%29/Global%20Development%20Operations/TAG%20Dashboard/TAG%20Dashboard&configurationBlock=SetPage%28pageIndex%3D0%29%3B&options=7-1,9-1,10-1,11-1,12-1,13-1,14-1,1-1,2-1,3-1,4-1,5-1,6-0,15-1,17-1',
//             disabled: false,
//           },
//           {
//             label: 'List of Product Team Members',
//             url: 'https://portfolio-analytics.amgen.com/dashboard/tlm-report',
//             disabled: false,
//           },
//           {
//             label: 'R&D KPI Dashboard',
//             url: 'https://amgen.sharepoint.com/sites/RDKPIDashboard',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//   },

//   {
//     label: 'Finance',
//     url: '/finance',
//     confidentiality: confidentialityMap.confidential_fa,
//   },
//   {
//     label: 'External',
//     url: '/external',
//     confidentiality: confidentialityMap.confidential_ga,
//     internalSections: [
//       {
//         sectionLabel: '',
//         links: [
//           {
//             label: 'Competitor Financials',
//             url: '/external/companies',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//     externalSections: [
//       {
//         sectionLabel: 'Related Links',
//         links: [
//           {
//             label: 'Analyst Reports',
//             url: 'https://amgen.account.box.com/login?redirect_url=%2Ffolder%2F153343064781&logout=true',
//             disabled: false,
//           },
//           {
//             label: 'Transcripts',
//             url: 'https://amgen.account.box.com/login?redirect_url=%2Ffolder%2F153343865830',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     label: 'About',
//     url: '/about',
//     confidentiality: confidentialityMap.internal_ga,
//   },
//   {
//     label: 'Admin',
//     url: '/admin',
//     confidentiality: confidentialityMap.internal_ga,
//     internalSections: [
//       {
//         sectionLabel: '',
//         links: [
//           {
//             label: 'Usage Metric',
//             url: '/admin/usage-metric',
//             disabled: false,
//           },
//           {
//             label: 'Access Request',
//             url: '/admin/sensing-access-request',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//     externalSections: [
//       {
//         sectionLabel: 'Related Links',
//         links: [
//           {
//             label: 'Matomo',
//             url: 'https://matomo-ext.devops.amgen.com/index.php?module=CoreHome&action=index&idSite=37&period=day&date=yesterday#?period=day&date=yesterday&category=Dashboard_Dashboard&subcategory=1',
//             disabled: false,
//           },
//         ],
//       },
//     ],
//   },
// ];
export const links : Array<NavLink> = [
  {
    label: 'Shop floor',
    url: '/aoh/shopfloor',
    confidentiality: confidentialityMap.internal_ga,
  },
]
