import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MainNav } from 'main-nav';
import { PermissionType } from 'types';
import { MATOMO } from 'utils/matomo-config';
import { createBrowserHistory } from 'history';
import { DEV_DIMENSIONS } from 'utils/dimensions';
import MatomoContextProvider from 'utils/matomo-context-provider';
import './global.min.css'

interface AppProps {
  permissions: PermissionType[];
}

const matomoClientID = MATOMO.config().siteId;
const matomoClientUrl = MATOMO.config().url;

const history = createBrowserHistory();

function App(props: AppProps) {
  const { permissions } = props;

  const router = createBrowserRouter([
    {
      path: '/*',
      element: <MainNav permissions={permissions} />,
      children: [],
    },
  ]);

  return (
    <MatomoContextProvider
      url={matomoClientUrl}
      siteId={matomoClientID}
      history={history}
      dimensions={DEV_DIMENSIONS}
    >
      <RouterProvider router={router} />
    </MatomoContextProvider>
  );
}

export default App;
